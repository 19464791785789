import React, { FC, useEffect } from 'react';
import { Box, Stack } from '@mui/material';

import { useGetAllOrdersAccounting } from '../../../../../graphql/queries/hook/useGetAllOrdersAccounting';
import { AllOrdersTable } from './AllOrdersTable';
import { AllOrdersToolbar } from './AllOrdersToolbar';
import { useLocalization } from '../../../../../localization';
import { addDays, format } from 'date-fns';
import { HelpSearchOrder } from '../../../../../components/HelpSearchOrder';
import { SearchInput } from '../../../../../components/SearchInput';
import { useAccountingOrdersSearchParams } from '../hooks/useAccountingOrdersSearchParams';
import { PAGE_COUNT } from '../../../../../helpers/constants';
import { ExportOrdersToSCV } from './ExportOrdersToSCV';
import { ExportOrdersToExcel } from './ExportOrdersToExcel';

interface Props {
  idUser?: string;
  isHistoryTab?: boolean;
}

const sort = ['date:desc', 'orderNumber:desc', 'orderNumberAdd'];

export const AllOrdersTab: FC<Props> = ({ idUser, isHistoryTab }) => {
  const { translateLang } = useLocalization();
  const {
    page,
    filters,
    statuses,
    searchText,
    changeParam,
    resetFilters,
    paymentStatus,
    selectedDates,
    changeSearchText,
  } = useAccountingOrdersSearchParams({
    defaultDateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
    defaultDateTo: format(new Date(), 'yyyy-MM-dd'),
    userId: idUser,
    isHistoryTab
  });

  const {
    allOrdersAccounting,
    totalPage,
    loading,
    refetch: refetchOrders,
  } = useGetAllOrdersAccounting({
    filters,
    page: parseInt(page),
    pageSize: PAGE_COUNT,
    sort,
  });

  useEffect(() => {
    refetchOrders();
  }, [allOrdersAccounting]);

  return (
    <Box width="100%">
      <Box width="100%">
        <Stack
          flexGrow={1}
          flexDirection="row"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          bgcolor="white"
          p={2}
        >
          <Box width="50%">
            <SearchInput
              value={searchText}
              onChangeText={changeSearchText}
              placeholder={translateLang('searchOrdersAccountingLabel')}
              endAdornment={<HelpSearchOrder />}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <ExportOrdersToExcel filters={filters} sort={sort} />
            <ExportOrdersToSCV filters={filters} sort={sort} />
          </Box>
        </Stack>
        <AllOrdersToolbar
          changeParam={changeParam}
          paymentStatus={paymentStatus}
          resetFilters={resetFilters}
          selectedDates={selectedDates}
          statuses={statuses}
        />
        <AllOrdersTable
          allOrdersAccounting={allOrdersAccounting}
          filters={filters}
          totalPage={totalPage}
          changePage={page => changeParam({ page: `${page + 1}` })}
          currentPage={parseInt(page)}
          loading={loading}
        />
      </Box>
    </Box>
  );
};
