import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { HistoryTable } from './HistoryTable';
import { ToolBarOrderHistory } from './ToolBarOrderHistory';
import { OrderFiltersInput } from '../../../__generated__/types';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';
import { AllOrdersTab } from '../../Accounting/components/AllOrders/components/AllOrdersTab';

export const OrderHistoryTab = () => {
  const [filters, setFilters] = useState<OrderFiltersInput>();
  const { idUser } = useParams();

  const { isSuperAdmin, isAccounter } = useGetMe();

  return (
    <>
      {isSuperAdmin || isAccounter ? (
        <AllOrdersTab idUser={idUser} isHistoryTab={true} />
      ) : (
        <>
          <ToolBarOrderHistory setFilterOrderStatus={setFilters} result="" />
          <HistoryTable statusFilter={filters} />
        </>
      )}
    </>
  );
};
