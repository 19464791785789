import React, { useEffect, useMemo, useRef, useState } from 'react';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { Input } from '../../../../legos';
import { theme } from '../../../../helpers';
import { InvoicePrint } from './InvoicePrint';
import { useValidation } from './hooks/useValidation';
import { useLocalization } from '../../../../localization';
import { CalendarInvoicePrint } from './CalendarInvoicePrint';
import { IncomingPrintFields } from './types';
import { useGetClientById } from '../../../../graphql/queries/hook/useGetClientById';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { useGetCustomerBillLazyQuery } from '../../../../graphql/queries/__generated__/getCustomerBill';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateCustomerBillMutation } from '../../../../graphql/mutations/__generated__/updateCustomerBill';
import { formatOrderNumber } from '../../../../utils/formatOrderNumber';
import { useGetCustomerContactInfoLazyQuery } from '../../../../graphql/queries/__generated__/getCustomerContactInfo';
import { useDeleteCustomerBillMutation } from '../../../../graphql/mutations/__generated__/deleteCustomerBill';
import { DeleteModal } from '../../../ClientInfo/components/Modals/DeleteModal';
import { PatchesName } from '../../../../types/types';
import { RolesEnum } from '../../../../types/types';
import { useGetMe } from '../../../../graphql/queries/hook/useGetMe';
import { IBAN } from '../../../../helpers/constants';
import { useDocx } from './hooks/useDocx';

export const InvoicePage = () => {
  const navigate = useNavigate();
  const { addNotification } = useHandlerNotificationApp();
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { handleDownloadDocx } = useDocx();
  const { invoiceId, userId = '' } = useParams();
  const { validationSchema } = useValidation();

  const { contactInfoId, legalAddress } = useGetClientById(userId);
  const { role } = useGetMe();
  const [loading, setLoading] = useState(false);
  const [pdfLoading, _] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [date, setDate] = useState<Date | null>(new Date());
  const [invoicePaid, setInvoicePaid] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('cashPayment');
  const divInvoiceRef = useRef<HTMLDivElement>(null);

  const [runGetCustomerBill, { data: customerBillData, refetch }] = useGetCustomerBillLazyQuery();
  const [runCustomerContactInfo, { data: customerContactInfoData }] =
    useGetCustomerContactInfoLazyQuery();
  const [runDeleteCustomerBill] = useDeleteCustomerBillMutation();

  const emailData = customerContactInfoData?.customerContactInfo?.data?.attributes?.emails?.map(
    item => item?.email
  );

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteInvoice = async () => {
    try {
      const response = await runDeleteCustomerBill({
        variables: {
          id: invoiceId ?? '',
        },
      });
      if (response) {
        closeDeleteModal();
        addNotification({
          messageError: translateLang('deletedSuccessfully'),
          typeMessage: 'success',
        });
        navigate(`/${PatchesName.Accounting}`);
      }
    } catch (e) {
      closeDeleteModal();
      console.error(e);
    }
  };

  const customerBillInfo = useMemo(
    () => ({
      ...customerBillData?.customerBill?.data?.attributes,
      ordersInvoice: customerBillData?.customerBill?.data?.attributes?.orders?.data.map(item => ({
        orderNumber: formatOrderNumber(
          item.attributes?.orderNumber,
          item.attributes?.orderNumberAdd
        ),
        deliveryAddress: item.attributes?.deliveryAddress,
        data: item.attributes?.order_items?.data.map(orderItem => ({
          title:
            orderItem.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes
              ?.title,
          count:
            (orderItem.attributes?.quantity?.orderedQuantity || 0) -
            (orderItem.attributes?.quantity?.returnedQuantity || 0),
          price: orderItem.attributes?.customer_price,
          brandName:
            orderItem.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes
              ?.brandName,
          article: orderItem.attributes?.car_spare?.data?.attributes?.tecdoc_articleNumber,
        })),
      })),
      email: customerBillData?.customerBill?.data?.attributes?.email,
      userContactInfo:
        customerBillData?.customerBill?.data?.attributes?.customer?.data?.attributes
          ?.customer_contact_info?.data?.attributes,
    }),
    [customerBillData?.customerBill?.data?.attributes]
  );

  const [updateCustomerBillMutation] = useUpdateCustomerBillMutation();

  const initialValues = {
    [IncomingPrintFields.BillNumber]: customerBillInfo?.billNumber ?? '',
    [IncomingPrintFields.Comment]: customerBillInfo?.comment ?? '',
    [IncomingPrintFields.CompanyName]: customerBillInfo?.userContactInfo?.companyName ?? '',
    [IncomingPrintFields.House]: '',
    [IncomingPrintFields.Street]: '',
    [IncomingPrintFields.City]: '',
    [IncomingPrintFields.ZipCode]: '',
    [IncomingPrintFields.VATId]: customerBillInfo?.userContactInfo?.VATId ?? '',
    [IncomingPrintFields.Email]: '',
  };

  if (customerBillInfo?.address) {
    initialValues[IncomingPrintFields.House] = customerBillInfo?.address?.number ?? '';
    initialValues[IncomingPrintFields.Street] = customerBillInfo?.address?.street ?? '';
    initialValues[IncomingPrintFields.City] = customerBillInfo?.address?.city ?? '';
    initialValues[IncomingPrintFields.ZipCode] = customerBillInfo?.address?.zipCode ?? '';
  } else if (legalAddress) {
    initialValues[IncomingPrintFields.House] = legalAddress?.number ?? '';
    initialValues[IncomingPrintFields.Street] = legalAddress?.street ?? '';
    initialValues[IncomingPrintFields.City] = legalAddress?.city ?? '';
    initialValues[IncomingPrintFields.ZipCode] = legalAddress?.zipCode ?? '';
  } else {
    initialValues[IncomingPrintFields.House] =
      customerBillInfo?.ordersInvoice?.[0]?.deliveryAddress?.number ?? '';
    initialValues[IncomingPrintFields.Street] =
      customerBillInfo?.ordersInvoice?.[0]?.deliveryAddress?.street ?? '';
    initialValues[IncomingPrintFields.City] =
      customerBillInfo?.ordersInvoice?.[0]?.deliveryAddress?.city ?? '';
    initialValues[IncomingPrintFields.ZipCode] =
      customerBillInfo?.ordersInvoice?.[0]?.deliveryAddress?.zipCode ?? '';
  }

  const { values, errors, touched, handleChange, handleSubmit, dirty, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({ street, house, city, zipCode, comment, billNumber, email }) => {
      setLoading(true);
      try {
        await updateCustomerBillMutation({
          variables: {
            id: customerBillData?.customerBill?.data?.id as string,
            data: {
              comment,
              billNumber: +billNumber,
              address: {
                street,
                number: house,
                city,
                zipCode,
              },
              email: email ? email : customerBillInfo?.email,
            },
          },
        });

        await refetch();
        addNotification({
          messageError: translateLang('updatedSuccessfully'),
          typeMessage: 'success',
        });

        setFieldValue(IncomingPrintFields.Email, '');
      } catch (err) {
        addNotification({
          messageError: (err as Error)?.message,
          typeMessage: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (invoiceId) {
      runGetCustomerBill({
        variables: {
          id: invoiceId,
          locale: updateSelectLanguage as string,
        },
      }).finally(() => setPageLoading(false));
    }
  }, []);

  useEffect(() => {
    if (!contactInfoId) return;
    runCustomerContactInfo({ variables: { id: contactInfoId } });
  }, [contactInfoId, runCustomerContactInfo]);

  useEffect(() => {
    if (customerBillInfo?.billDate) {
      setDate(new Date(customerBillInfo?.billDate));
    }
  }, [customerBillInfo?.billDate]);

  const handleDateChange = async (newValue: Date | null) => {
    await updateCustomerBillMutation({
      variables: {
        id: customerBillData?.customerBill?.data?.id as string,
        data: {
          billDate: format(newValue ?? new Date(), 'yyyy-MM-dd'),
        },
      },
    })
      .then(() => {
        setDate(newValue);
        refetch();
        addNotification({
          messageError: translateLang('invoiceDateUpdated'),
          typeMessage: 'success',
        });
      })
      .catch(err => {
        addNotification({ messageError: err.message, typeMessage: 'error' });
      });
  };

  const runInvoicePrint = () => {
    const ifram = document.createElement('iframe');
    ifram.style.display = 'none';
    document.body.appendChild(ifram);
    const pri = ifram.contentWindow;

    if (pri) {
      pri.document.open();
      if (divInvoiceRef.current) {
        const customInnerHTML = divInvoiceRef.current.innerHTML
          .replace(
            '<input',
            `<input style='border: none; font-size: 12px; font-weight: 700; margin-left: 16px'`
          )
          .replace('class="MuiInputAdornment', `style='display:none' class="MuiInputAdornment`);
        pri.document.write(customInnerHTML);
      }
      pri.document.close();
      pri.focus();
      pri.print();
    }
    setTimeout(() => {
      document.body.removeChild(ifram);
    }, 500);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const toggleInvoicePaid = () => {
    setInvoicePaid(!invoicePaid);
  };

  const billNumber = `${new Date(customerBillInfo?.billDate).getFullYear()}/${
    values[IncomingPrintFields.BillNumber]
  }`;

  const total = customerBillInfo?.total || 0;

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod((event.target as HTMLInputElement).value);
  };

  return (
    <Stack mb={4}>
      <Box mb={2.5}>
        <IconButton onClick={handleGoBack}>
          <ArrowBack sx={{ color: theme.palette.common.black }} />
        </IconButton>
      </Box>
      {!pageLoading ? (
        <Stack
          maxWidth={1300}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          gap={5}
        >
          <InvoicePrint
            ref={divInvoiceRef}
            total={total}
            invoicePaid={invoicePaid}
            invoicePaidComment={values[IncomingPrintFields.Comment]}
            billInfo={{
              companyName: values[IncomingPrintFields.CompanyName],
              VATId: values[IncomingPrintFields.VATId],
              street: values[IncomingPrintFields.Street],
              house: values[IncomingPrintFields.House],
              city: values[IncomingPrintFields.City],
              zipCode: values[IncomingPrintFields.ZipCode],
              email: values[IncomingPrintFields.Email]
                ? values[IncomingPrintFields.Email] ?? ''
                : customerBillInfo.email ?? '',
            }}
            billNumber={billNumber}
            ordersInvoice={customerBillInfo?.ordersInvoice}
            date={date}
          />

          <Stack component="form" justifyContent="space-between" gap={2} onSubmit={handleSubmit}>
            <Stack flexDirection="column" gap={2}>
              <TranslatedField
                originText={'documentSettings'}
                fontSize={28}
                fontWeight={600}
                isTranslate
                noWrap
                overflow="initial"
              />
              <Stack flexDirection="row" gap={1.25} alignItems="flex-start">
                <CalendarInvoicePrint
                  disableFuture
                  value={date}
                  isLabel={false}
                  setValue={handleDateChange}
                  inputStyle={{
                    bgcolor: theme.palette.common.white,
                    height: 40,
                    p: 0,
                    '& .MuiInputBase-root': {
                      width: '100%',
                      fontFamily: 'Mulish,sans-serif',
                      fontSize: '12px',
                      fontWeight: 700,
                      lineHeight: 1.5,
                      p: '9px 15px',
                    },
                  }}
                />
                <Input
                  name={IncomingPrintFields.BillNumber}
                  value={values[IncomingPrintFields.BillNumber]}
                  onChange={handleChange}
                  error={
                    touched[IncomingPrintFields.BillNumber] &&
                    !!errors[IncomingPrintFields.BillNumber]
                  }
                  helperText={
                    touched[IncomingPrintFields.BillNumber] &&
                    errors[IncomingPrintFields.BillNumber]
                  }
                  bgcolor={theme.palette.common.white}
                  inputProps={{
                    style: {
                      height: 12,
                      width: '100%',
                    },
                  }}
                />
              </Stack>
              <Stack flexDirection="row" gap={1.25} alignItems="center">
                <Checkbox checked={invoicePaid} onChange={toggleInvoicePaid} />
                <TranslatedField
                  originText={'invoicePaid'}
                  fontSize={20}
                  fontWeight={500}
                  isTranslate
                  noWrap
                  overflow="initial"
                />
              </Stack>
              {invoicePaid ? (
                <Stack flexDirection="column" gap={2}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      {translateLang('paymentMethod')}
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={paymentMethod}
                      onChange={handlePaymentMethodChange}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <FormControlLabel
                        value="cashPayment"
                        control={<Radio />}
                        label={translateLang('cashPayment')}
                      />
                      <FormControlLabel
                        value="bankTransfer"
                        control={<Radio />}
                        label={translateLang('bankTransfer')}
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    multiline
                    rows={4}
                    name={IncomingPrintFields.Comment}
                    value={values[IncomingPrintFields.Comment]}
                    onChange={handleChange}
                    label={translateLang('comment')}
                  />
                </Stack>
              ) : null}

              <Stack mt={3} flexGrow={1} spacing={2}>
                <Stack>
                  <InputLabel style={{ fontSize: '12px' }} htmlFor="email-select">
                    {translateLang('email')}
                  </InputLabel>
                  <Select
                    size="small"
                    id="email-select"
                    style={{
                      background: theme.palette.common.white,
                      height: 40,
                    }}
                    name={IncomingPrintFields.Email}
                    value={values[IncomingPrintFields.Email] || customerBillInfo?.email || ''}
                    onChange={handleChange}
                  >
                    {emailData?.map(email =>
                      email ? (
                        <MenuItem key={email} value={email}>
                          {email}
                        </MenuItem>
                      ) : null
                    )}
                  </Select>
                </Stack>
                <Stack height={75} gap={2} direction="row">
                  <Input
                    label={translateLang('companyName')}
                    name={IncomingPrintFields.CompanyName}
                    value={values[IncomingPrintFields.CompanyName]}
                    onChange={handleChange}
                    error={
                      touched[IncomingPrintFields.CompanyName] &&
                      !!errors[IncomingPrintFields.CompanyName]
                    }
                    helperText={
                      touched[IncomingPrintFields.CompanyName] &&
                      errors[IncomingPrintFields.CompanyName]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                  <Input
                    label={translateLang('VATId')}
                    name={IncomingPrintFields.VATId}
                    value={values[IncomingPrintFields.VATId]}
                    onChange={handleChange}
                    error={
                      touched[IncomingPrintFields.VATId] && !!errors[IncomingPrintFields.VATId]
                    }
                    helperText={
                      touched[IncomingPrintFields.VATId] && errors[IncomingPrintFields.VATId]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                </Stack>
                <Stack height={75} gap={2} direction="row">
                  <Input
                    label={`${translateLang('street')}`}
                    name={IncomingPrintFields.Street}
                    value={values[IncomingPrintFields.Street]}
                    onChange={handleChange}
                    error={
                      touched[IncomingPrintFields.Street] && !!errors[IncomingPrintFields.Street]
                    }
                    helperText={
                      touched[IncomingPrintFields.Street] && errors[IncomingPrintFields.Street]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                  <Input
                    label={`${translateLang('house')}`}
                    name={IncomingPrintFields.House}
                    value={values[IncomingPrintFields.House]}
                    onChange={handleChange}
                    error={
                      touched[IncomingPrintFields.House] && !!errors[IncomingPrintFields.House]
                    }
                    helperText={
                      touched[IncomingPrintFields.House] && errors[IncomingPrintFields.House]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                </Stack>
                <Stack height={75} gap={2} direction="row">
                  <Input
                    label={`${translateLang('city')}`}
                    name={IncomingPrintFields.City}
                    value={values[IncomingPrintFields.City]}
                    onChange={handleChange}
                    error={
                      !!(touched[IncomingPrintFields.City] && !!errors[IncomingPrintFields.City])
                    }
                    helperText={
                      touched[IncomingPrintFields.City] && errors[IncomingPrintFields.City]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                  <Input
                    label={`${translateLang('zip')}`}
                    name={IncomingPrintFields.ZipCode}
                    value={values[IncomingPrintFields.ZipCode]}
                    onChange={handleChange}
                    error={
                      !!(
                        touched[IncomingPrintFields.ZipCode] &&
                        !!errors[IncomingPrintFields.ZipCode]
                      )
                    }
                    helperText={
                      touched[IncomingPrintFields.ZipCode] && errors[IncomingPrintFields.ZipCode]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                </Stack>

                <Button
                  disabled={!dirty}
                  color="secondary"
                  variant="contained"
                  size="large"
                  type="submit"
                  style={{
                    width: 200,
                    maxWidth: 200,
                    margin: '0 auto',
                  }}
                >
                  <TranslatedField originText="save" isTranslate color="white" />
                  {loading && <CircularProgress color="inherit" size={16} sx={{ marginLeft: 1 }} />}
                </Button>
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={pdfLoading}
                sx={{
                  height: 50,
                  textTransform: 'none',
                }}
                onClick={() =>
                  handleDownloadDocx({
                    billInfo: {
                      companyName: values[IncomingPrintFields.CompanyName],
                      VATId: values[IncomingPrintFields.VATId],
                      street: values[IncomingPrintFields.Street],
                      house: values[IncomingPrintFields.House],
                      city: values[IncomingPrintFields.City],
                      zipCode: values[IncomingPrintFields.ZipCode],
                      email: values[IncomingPrintFields.Email]
                        ? values[IncomingPrintFields.Email] ?? ''
                        : customerBillInfo.email ?? '',
                    },
                    ordersInvoice: customerBillInfo?.ordersInvoice,
                    invoicePaid: invoicePaid,
                    invoicePaidComment: customerBillInfo.comment ?? null,
                    billNumber: customerBillInfo?.billNumber ?? null,
                    total: customerBillInfo?.total ?? null,
                    date: customerBillInfo?.billDate,
                    IBAN: IBAN,
                  })
                }
              >
                {translateLang('download')}
              </Button>
              <Stack gap={2} direction="row">
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  sx={{
                    height: 50,
                    textTransform: 'none',
                  }}
                  onClick={runInvoicePrint}
                >
                  <TranslatedField
                    originText={'print'}
                    fontSize={16}
                    isTranslate
                    noWrap
                    overflow="initial"
                  />
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  sx={{
                    height: 50,
                    textTransform: 'none',
                  }}
                >
                  <TranslatedField
                    originText={'send'}
                    fontSize={16}
                    isTranslate
                    noWrap
                    overflow="initial"
                  />
                </Button>
              </Stack>
              {(role === RolesEnum.Accounter || role === RolesEnum.SuperAdmin) && (
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  disabled={pdfLoading}
                  sx={{
                    height: 50,
                    textTransform: 'none',
                  }}
                  onClick={() => setOpenDeleteModal(true)}
                >
                  <TranslatedField
                    originText={'deleteInvoice'}
                    fontSize={16}
                    isTranslate
                    noWrap
                    overflow="initial"
                  />
                  {pdfLoading && (
                    <CircularProgress color="inherit" size={16} sx={{ marginLeft: 1 }} />
                  )}
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack justifyContent="center" alignItems="center" mt={10}>
          <CircularProgress size={40} />
        </Stack>
      )}
      {isOpenDeleteModal && (
        <DeleteModal
          openModal={isOpenDeleteModal}
          closeModal={closeDeleteModal}
          title={translateLang('deleteInvoice')}
          text={translateLang('wantDeleteInvoice')}
          handlerDelete={deleteInvoice}
        />
      )}
    </Stack>
  );
};
