import {
  Document,
  Packer,
  Paragraph,
  ImageRun,
  TableCell,
  TextRun,
  TableRow,
  Table,
  WidthType,
  AlignmentType,
  BorderStyle,
} from 'docx';
import { invoiceFooterData } from '../helpers';
import logo from '../../../../../assets/Logo.png';
import { saveAs } from 'file-saver';

interface DownloadDocxProps {
  billInfo?: {
    companyName: string | null;
    VATId: string | null;
    street: string | null;
    house: string | null;
    city: string | null;
    zipCode: string | null;
    email: string | null;
  };
  ordersInvoice?: {
    orderNumber?: string | null;
    data?: {
      title?: string | null;
      count: number;
      price?: number | null;
      brandName?: string | null;
      article?: string | null;
    }[];
  }[];
  invoicePaid: boolean;
  invoicePaidComment: string | null;
  billNumber: number | null;
  total: number | null;
  date: Date | null;
  IBAN: string | null;
}

export const useDocx = () => {
  const loadImageAsUint8Array = async (url: string): Promise<Uint8Array> => {
    const response = await fetch(url);
    const blob = await response.blob();
    const arrayBuffer = await blob.arrayBuffer();
    return new Uint8Array(arrayBuffer);
  };

  const handleDownloadDocx = async ({
    billInfo,
    ordersInvoice,
    invoicePaid,
    invoicePaidComment,
    billNumber,
    total,
    date,
    IBAN,
  }: DownloadDocxProps) => {
    const formattedDate = new Date(date || new Date()).toLocaleDateString('de-DE');
    const paymentDueDate = new Date(date || new Date());
    paymentDueDate.setDate(paymentDueDate.getDate() + 14);
    const formattedDueDate = paymentDueDate.toLocaleDateString('de-DE');

    const tableRows: TableRow[] = [];
    const logoUint8Array = await loadImageAsUint8Array(logo);

    tableRows.push(
      new TableRow({
        children: [
          new TableCell({
            width: { size: 4000, type: WidthType.DXA },
            borders: {
              top: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
              bottom: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
              left: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
            },
            shading: {
              fill: '#F44336',
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Pos. Beschreibung',
                    bold: true,
                    color: '#ffffff',
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            width: { size: 1500, type: WidthType.DXA },
            borders: {
              top: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
              bottom: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
            },
            shading: {
              fill: '#F44336',
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Menge',
                    bold: true,
                    color: '#ffffff',
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
          }),

          new TableCell({
            width: { size: 2000, type: WidthType.DXA },
            borders: {
              top: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
              bottom: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
            },
            shading: {
              fill: '#F44336',
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Einzelpreis EUR',
                    bold: true,
                    color: '#ffffff',
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
          }),
          new TableCell({
            width: { size: 2000, type: WidthType.DXA },
            borders: {
              top: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
              bottom: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
              right: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
            },
            shading: {
              fill: '#F44336',
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Betrag EUR',
                    bold: true,
                    color: '#ffffff',
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
          }),
        ],
      })
    );

    ordersInvoice?.forEach(order => {
      if (order.orderNumber) {
        tableRows.push(
          new TableRow({
            children: [
              new TableCell({
                columnSpan: 4,
                borders: {
                  bottom: { style: BorderStyle.NONE },
                  top: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `Lieferschein ${order.orderNumber}`,
                        bold: true,
                        size: 18,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          })
        );

        order.data?.forEach((item, index) => {
          tableRows.push(
            new TableRow({
              children: [
                new TableCell({
                  borders: {
                    bottom: { style: BorderStyle.NONE },
                    left: { style: BorderStyle.NONE },
                    right: { style: BorderStyle.NONE },
                  },
                  children: [
                    new Paragraph({
                      text: `${index + 1}. ${item.article} | ${item.brandName} | ${item.title}`,
                    }),
                  ],
                }),
                new TableCell({
                  borders: {
                    bottom: { style: BorderStyle.NONE },
                    left: { style: BorderStyle.NONE },
                    right: { style: BorderStyle.NONE },
                  },
                  children: [
                    new Paragraph({
                      text: `${item.count}`,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),
                new TableCell({
                  borders: {
                    bottom: { style: BorderStyle.NONE },
                    left: { style: BorderStyle.NONE },
                    right: { style: BorderStyle.NONE },
                  },
                  children: [
                    new Paragraph({
                      text: `${item.price?.toFixed(2) || '0.00'}`,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),
                new TableCell({
                  borders: {
                    bottom: { style: BorderStyle.NONE },
                    left: { style: BorderStyle.NONE },
                    right: { style: BorderStyle.NONE },
                  },
                  children: [
                    new Paragraph({
                      text: `${(item.count * (item.price || 0)).toFixed(2)}`,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),
              ],
            })
          );
        });
      }
    });

    tableRows.push(
      new TableRow({
        children: [
          new TableCell({
            borders: {
              top: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
              left: { style: BorderStyle.NONE },
              right: { style: BorderStyle.NONE },
            },
            children: [new Paragraph('')],
          }),
          new TableCell({
            columnSpan: 2,
            borders: {
              top: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
              bottom: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
              left: { style: BorderStyle.NONE },
              right: { style: BorderStyle.NONE },
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Netto',
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            borders: {
              top: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
              bottom: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
              left: { style: BorderStyle.NONE },
              right: { style: BorderStyle.NONE },
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${((total ?? 0) - ((total ?? 0) * 0.2) / 1.2).toFixed(2)}`,
                    bold: true,
                  }),
                ],
                alignment: 'right',
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            borders: {
              left: { style: BorderStyle.NONE },
              right: { style: BorderStyle.NONE },
            },
            children: [new Paragraph('')],
          }),
          new TableCell({
            columnSpan: 2,
            borders: {
              bottom: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
              left: { style: BorderStyle.NONE },
              right: { style: BorderStyle.NONE },
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'zzgl. USt. 20%',
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            borders: {
              bottom: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
              left: { style: BorderStyle.NONE },
              right: { style: BorderStyle.NONE },
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${(((total ?? 0) * 0.2) / 1.2).toFixed(2)}`,
                    bold: true,
                  }),
                ],
                alignment: 'right',
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            borders: {
              left: { style: BorderStyle.NONE },
              right: { style: BorderStyle.NONE },
            },
            children: [new Paragraph('')],
          }),
          new TableCell({
            columnSpan: 2,
            borders: {
              left: { style: BorderStyle.NONE },
              right: { style: BorderStyle.NONE },
            },
            shading: {
              fill: '#F44336',
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Gesamtsumme EUR',
                    bold: true,
                    color: '#ffffff',
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            borders: {
              left: { style: BorderStyle.NONE },
              right: { style: BorderStyle.NONE },
            },
            shading: {
              fill: '#F44336',
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${total?.toFixed(2)}`,
                    bold: true,
                    color: '#ffffff',
                  }),
                ],
                alignment: 'right',
              }),
            ],
          }),
        ],
      })
    );

    const footerTable = new Table({
      width: { size: 10000, type: WidthType.DXA },
      columnWidths: [2500, 2500, 2500, 2500],
      borders: {
        bottom: { style: BorderStyle.NONE },
        left: { style: BorderStyle.NONE },
        right: { style: BorderStyle.NONE },
        insideHorizontal: { style: BorderStyle.NONE },
        insideVertical: { style: BorderStyle.NONE },
      },
      rows: [
        new TableRow({
          children: invoiceFooterData.map(
            item =>
              new TableCell({
                borders: {
                  top: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
                  bottom: { style: BorderStyle.NONE },
                  left: { style: BorderStyle.NONE },
                  right: { style: BorderStyle.NONE },
                },
                children: [
                  new Paragraph({
                    children: item.text.map(line => new TextRun({ text: line, break: 1 })),
                    alignment: AlignmentType.CENTER,
                  }),
                ],
              })
          ),
        }),
      ],
    });
    const doc = new Document({
      sections: [
        {
          children: [
            new Table({
              width: { size: 10000, type: WidthType.DXA },
              columnWidths: [5000, 1500, 3500],
              borders: {
                top: { style: BorderStyle.NONE },
                bottom: { style: BorderStyle.SINGLE, size: 10, color: '#F44336' },
                left: { style: BorderStyle.NONE },
                right: { style: BorderStyle.NONE },
                insideHorizontal: { style: BorderStyle.NONE },
                insideVertical: { style: BorderStyle.NONE },
              },
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: 'RECHNUNG',
                              color: '#808080',
                              size: 36,
                              font: 'Mulish, sans-serif',
                            }),
                          ],
                          alignment: AlignmentType.LEFT,
                        }),
                      ],
                    }),
                    new TableCell({ children: [new Paragraph('')] }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new ImageRun({
                              data: logoUint8Array,
                              transformation: {
                                width: 210,
                                height: 32,
                              },
                              type: 'png',
                            }),
                          ],
                          alignment: AlignmentType.RIGHT,
                        }),
                      ],
                    }),
                  ],
                }),
              ],
            }),
            new Paragraph({ text: '' }),
            new Table({
              width: { size: 10000, type: WidthType.DXA },
              columnWidths: [4000, 2000, 4000],
              borders: {
                top: { style: BorderStyle.NONE },
                bottom: { style: BorderStyle.NONE },
                left: { style: BorderStyle.NONE },
                right: { style: BorderStyle.NONE },
                insideHorizontal: { style: BorderStyle.NONE },
                insideVertical: { style: BorderStyle.NONE },
              },
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `${billInfo?.email ?? ''}`,
                              bold: true,
                              font: 'Mulish, sans-serif',
                            }),
                          ],
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `${billInfo?.companyName ?? ''}`,
                              bold: true,
                              font: 'Mulish, sans-serif',
                            }),
                          ],
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `${billInfo?.street ?? ''} ${billInfo?.house ?? ''}`,
                              bold: true,
                              font: 'Mulish, sans-serif',
                            }),
                          ],
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `${billInfo?.zipCode ?? ''} ${billInfo?.city ?? ''}`,
                              bold: true,
                              font: 'Mulish, sans-serif',
                            }),
                          ],
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `${billInfo?.VATId ?? ''}`,
                              bold: true,
                              font: 'Mulish, sans-serif',
                            }),
                          ],
                        }),
                      ],
                    }),
                    new TableCell({ children: [new Paragraph('')] }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `Rechnung Nr: ${billNumber}`,
                              bold: true,
                              font: 'Mulish, sans-serif',
                            }),
                          ],
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `Rechnungsdatum: ${formattedDate}`,
                              bold: true,
                              font: 'Mulish, sans-serif',
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
              ],
            }),

            new Paragraph({ text: '' }),

            new Table({
              width: { size: 10000, type: WidthType.DXA },
              columnWidths: [4000, 1500, 2000, 2000],
              borders: {
                bottom: { style: BorderStyle.NONE },
                top: { style: BorderStyle.NONE },
                left: { style: BorderStyle.NONE },
                right: { style: BorderStyle.NONE },
                insideHorizontal: { style: BorderStyle.NONE },
                insideVertical: { style: BorderStyle.NONE },
              },
              rows: tableRows,
            }),

            new Paragraph({ text: '', spacing: { after: 400 } }),

            new Paragraph(
              invoicePaid
                ? {
                    children: [
                      new TextRun({
                        text: invoicePaidComment ?? '',
                        size: 18,
                        font: 'Mulish, sans-serif',
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                    spacing: { after: 600 },
                  }
                : {
                    children: [
                      new TextRun({
                        text: 'Zahlungsbedingungen: ',
                        bold: true,
                        size: 18,
                        font: 'Mulish, sans-serif',
                      }),
                      new TextRun({
                        text: `Bitte überweisen Sie den Betrag bis`,
                        size: 18,
                        font: 'Mulish, sans-serif',
                      }),
                      new TextRun({
                        text: ` ${formattedDueDate} `,
                        bold: true,
                        size: 18,
                        font: 'Mulish, sans-serif',
                      }),
                      new TextRun({
                        text: `unter Angabe der Zahlungsreferenz `,
                        size: 18,
                        font: 'Mulish, sans-serif',
                      }),
                      new TextRun({
                        text: `${billNumber}`,
                        bold: true,
                        size: 18,
                        font: 'Mulish, sans-serif',
                      }),
                      new TextRun({
                        text: ' auf das Konto IBAN: ',
                        size: 18,
                        font: 'Mulish, sans-serif',
                      }),
                      new TextRun({
                        text: `${IBAN}.`,
                        bold: true,
                        size: 18,
                        font: 'Mulish, sans-serif',
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                    spacing: { after: 600 },
                  }
            ),

            footerTable,
          ],
        },
      ],
    });

    Packer.toBlob(doc).then(blob => {
      saveAs(blob, `Invoice_${billNumber}.docx`);
    });
  };

  return {
    handleDownloadDocx,
  };
};
