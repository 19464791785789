import React, { useEffect } from 'react';
import { useGetWarehouse } from '../../../../graphql/queries/hook/useGetWarehouse';
import { Checkbox, Stack } from '@mui/material';
import { WarhouseOrderTabTable } from './components/WarhouseOrderTabTable';
import { useLocalization } from '../../../../localization';
import { useWarehouseWarehouseTabSearchParams } from './hooks/useWarehouseWarehouseTabSearchParams';
import { PAGE_COUNT } from '../../../../helpers/constants';
import { SearchInput } from '../../../../components/SearchInput';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { WrapperToolBar } from '../../../../components/WrapperToolBar';
import { updateSortList } from '../../../../utils/sortUtils';
import { InputMaybe, StockFiltersInput } from '../../../../__generated__/types';
import { ProviderReturnsItemsType } from '../ProvidersReturnTab/ProviderReturnedItemsPage';

type Props = {
  isProviderReturn: boolean;
  provider?: string;
  providerId?: string;
  returnId?: string;
  providerReturnsItems?: ProviderReturnsItemsType;
  refetchProviderReturnItems?: () => void;
};

export const WarehouseTab = ({
  isProviderReturn,
  providerId,
  returnId,
  providerReturnsItems,
  refetchProviderReturnItems,
}: Props) => {
  const { translateLang } = useLocalization();
  const { changSearchText, filters, page, searchText, changeParam, showZeroBalance, sort } =
    useWarehouseWarehouseTabSearchParams();
  const filtersProviders = () => {
    const newFilters: InputMaybe<StockFiltersInput> = { and: [] };
    if (isProviderReturn && providerId) {
      newFilters.and?.push({
        or: [{ supplier: { id: { eq: providerId } } }],
      });
    }
    if (searchText) {
      newFilters.and?.push({
        or: [
          {
            car_spare: {
              or: [
                { tecdoc_articleNumber: { containsi: searchText } },
                { car_spare_locales: { title: { containsi: searchText } } },
              ],
            },
          },
          { supplier: { name: { containsi: searchText } } },
        ],
      });
    }
    return newFilters;
  };

  const { itemsWarehouse, loading, totalPage, refetch } = useGetWarehouse({
    filters: isProviderReturn ? filtersProviders() : filters,
    page: parseInt(page),
    pageSize: PAGE_COUNT,
    sort,
  });

  useEffect(() => {
    refetch();
  }, [itemsWarehouse]);

  const sortHandler = (field: string) => {
    const updatedSortList = updateSortList(sort, field);
    changeParam({ sort: updatedSortList });
  };

  return (
    <Stack width="100%">
      <SearchInput
        value={searchText}
        onChangeText={text => changSearchText(text)}
        placeholder={translateLang('searchByPartName')}
      />
      {!isProviderReturn ? (
        <WrapperToolBar justifyContent="flex-end">
          <Stack flexDirection="row" alignItems="center">
            <TranslatedField
              originText={'showZeroBalance'}
              fontSize={16}
              isTranslate
              noWrap
              overflow="initial"
            />
            <Checkbox
              checked={showZeroBalance}
              onChange={(e, checked) => {
                changeParam({ showZeroBalance: checked ? 'true' : '' });
              }}
            />
          </Stack>
        </WrapperToolBar>
      ) : null}
      <WarhouseOrderTabTable
        itemsWarehouse={itemsWarehouse}
        totalPage={totalPage}
        changePage={page => changeParam({ page: `${page + 1}` })}
        currentPage={parseInt(page)}
        loading={loading}
        sort={sort}
        sortHandler={sortHandler}
        isProviderReturn={isProviderReturn}
        returnId={returnId ?? ''}
        providerReturnsItems={providerReturnsItems}
        refetchProviderReturnItems={refetchProviderReturnItems}
      />
    </Stack>
  );
};
